import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { TextPlugin } from 'gsap/TextPlugin.js'
import { SplitText } from "gsap/dist/SplitText"
import { ScrollSmoother } from 'gsap/ScrollSmoother.js'
import {Vector2, Vector3, TextureLoader, ShaderMaterial, PerspectiveCamera, Scene, WebGLRenderer, LinearFilter, sRGBEncoding, Mesh, PlaneGeometry} from 'three'
import AjaxPageLoader from '@lyssal/ajax-page-loader/lib/ajax-page-loader.amd'

let header = document.querySelector('header'),
	footer = document.querySelector('footer'),
	body = document.querySelector('body'),
	smootherWrap = document.getElementById('smooth-wrapper'),
	burgerButton = document.querySelector('.burger_button'),
	lazy = document.querySelectorAll('.lazy'),
	loader = document.querySelector('.loader'),
	loaderLogo = document.querySelector('.loader svg'),
 	page = body.getAttribute('id'),
	htmlMouse = {x: 0, y: 0},
	isGL,
	canCerchio,
	mouseMoving,
	isPageReady,
	isMenuOpened,
	floatingButtons,
	floatingTL,
	upTL,
	clientsTL,
	isScrolling,
	lastWindowWidth = 0,
	Flickity,
	vh,
	isMobile,
	isTouch,
	smoother;

let gl_container, getUniforms, camera, scene, renderer, texLoader, fov, gl_Material, scrollSpeed;
let perspective = 2000
let gl_meshes = []

let checkScrollSpeed = (function(val){

	var lastPos, newPos, timer, delta, 

	delay = 50

	function clear() {
		lastPos = null
		delta = 0
	}

	return function(val){

		newPos = val

		if ( lastPos != null ){
			delta = newPos - lastPos
		}

		lastPos = newPos

		clearTimeout(timer)

		timer = setTimeout(clear, delay)

		return delta

	}

})()

function buildPages() {

	if(loader){
		isGL = loader.dataset.gl
	}

	gsap.config({ nullTargetWarn: false })
	gsap.registerPlugin(ScrollTrigger)
	gsap.registerPlugin(TextPlugin)
	gsap.registerPlugin(SplitText)
	gsap.registerPlugin(ScrollSmoother)
	Flickity = require('flickity')

	Flickity.prototype.handleDragMove = function (event, pointer, moveVector) {

		if ( !this.isDraggable ) return;

		event.preventDefault();

		this.previousDragX = this.dragX;

		let direction = this.options.rightToLeft ? -1 : 1;

		if ( this.isWrapping ) moveVector.x %= this.slideableWidth;

		let dragX = this.dragStartPosition + moveVector.x * direction * (isMobile || window.innerWidth <= 580 ? 1 : 4);

		if ( this.options.bounce ) {

			if ( !this.options.wrapAround && this.slides.length ) {

				var originBound = Math.max( -this.slides[0].target, this.dragStartPosition );
				dragX = dragX > originBound ? ( dragX + originBound ) * 0.5 : dragX;
				var endBound = Math.min( -this.getLastSlide().target, this.dragStartPosition );
				dragX = dragX < endBound ? ( dragX + endBound ) * 0.5 : dragX;
			}

		} else {

			var originBound = Math.max(-this.slides[0].target, this.dragStartPosition)

			var endBound = Math.min(-this.getLastSlide().target, this.dragStartPosition)

			if (dragX > originBound) { dragX = originBound }

			if (dragX < endBound) { dragX = endBound }

		}

		this.dragX = dragX

		this.dragMoveTime = new Date()

		this.dispatchEvent('handleDragMove', event, [pointer, moveVector])
	}

	if( (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)) ) {
		isTouch = true
		body.classList.add('isTouch')
	}

	if( (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ) {
		isMobile = true
		body.classList.add('isMobile')
	} else {
		body.classList.add('isDesktop')
	}

	vh = window.innerHeight * 0.01;

	document.documentElement.style.setProperty('--vh', `${vh}px`)

	// gsap.to(loaderLogo, 1, {autoAlpha: 1, ease: 'power3.out'})

	onload()

}

window.addEventListener('load', buildPages)

function onload(){

	document.querySelector('body').classList.add('progress')

	loadFonts()

}

function flickFix($carousel){

	$carousel.on('dragStart', function(){
		$carousel.slider.childNodes.forEach(slide => slide.style.pointerEvents = "none")
		if(isMobile){
			stopScroll()
		}
	})

	$carousel.on('dragEnd', function(){
		$carousel.slider.childNodes.forEach(slide => slide.style.pointerEvents = "all")
		if(isMobile){
			startScroll()
		}
	})

}

function loadFonts(){

	const fonts = []
	const font_1 = new FontFace('neufile_groteskregular', 'url(/resources/fonts/neufilegrotesk-regular.woff) format("woff"), url(/resources/fonts/neufilegrotesk-regular.woff2) format("woff2")', { style: 'normal', weight: 400, 'font-display': 'swap' })
	const font_2 = new FontFace('neufile_grotesksemibold', 'url(/resources/fonts/neufilegrotesk-semibold.woff) format("woff"), url(/resources/fonts/neufilegrotesk-semibold.woff2) format("woff2")', { style: 'normal', weight: 400, 'font-display': 'swap' })
	const font_3 = new FontFace('neufile_grotesk_boldbold', 'url(/resources/fonts/neufilegrotesk-bold.woff) format("woff"), url(/resources/fonts/neufilegrotesk-bold.woff2) format("woff2")', { style: 'normal', weight: 400, 'font-display': 'swap' })
	let loadedFonts = 0

	fonts.push(font_1, font_2, font_3)

	fonts.forEach(function(e){

		e.load().then(function(loadedFont) {

			document.fonts.add(loadedFont)

			if(loadedFonts == 2){

				fire()

			}

			loadedFonts ++

		})

	})

}

function smootherFunc(){

	if(smoother) {

		smoother.kill()
		ScrollTrigger.refresh()
		smoother = null

	}

	smoother = ScrollSmoother.create({
		smooth: 1,
		ignoreMobileResize: true,
		effects: true,
		smoothTouch: false,
		normalizeScroll: page == 'case' ? false : true,
	})

}

function createSmooth(){

	if(page != 'not-found') {

		appendImgs()

		siteLinks()

		globalFunction()

		smootherFunc()

		setTimeout(function(){

			gsap.set(smoother, {scrollTop: 0})

			smoother.scrollTop(0, false)

			window.scroll(0, 0)

			stickyFunction()

			isPageReady = true

		}, 100)

		stopScroll()

	}

	interactionY()

}

function siteLinks(){

	let a = document.querySelectorAll('a')

	window.addEventListener( "pageshow", function ( event ) {
		var historyTraversal = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 )
		if ( historyTraversal ) {
			window.location.reload()
		}
	})

	a.forEach(function(e){

		if(isTouch) {

			if(e.closest('.menu_wrapper')) {

				e.addEventListener('touchend', function(event){
					handler(e, event)
				})

			} else {

				e.addEventListener('click', function(event){
					handler(e, event)
				})

			}

		} else {

			e.addEventListener('click', function(event){
				handler(e, event)
			})

		}

	})

	function handler(e, event){

		if(!e.getAttribute('target') && e.getAttribute('href') != '#' && !e.getAttribute('data-ajax')) {

			if(!isScrolling) {

				let pageTL = new gsap.timeline()

				pageTL.to(loader, 0.5, {autoAlpha: 1, ease: 'power3.out'}, 0)

				.call(function(){

					window.location = e.href

				})

			}

			event.preventDefault()

			return false

		}

	}

}

function getTranslate(myElement) {

	const style = window.getComputedStyle(myElement)
	const matrix = new DOMMatrixReadOnly(style.transform)
	return {
		x: matrix.m41,
		y: matrix.m42
	}

}

function limitNumberWithinRange(num, min, max){

	const MIN = min || 1;
	const MAX = max || 20;
	const parsed = num
	return Math.min(Math.max(parsed, MIN), MAX)

}

function fire(){

	let loaderTL = new gsap.timeline({delay: 0.5}),
		delayA,
		delayB,
		delayC;

	if(page == 'home') {
		delayA = 0.7
		delayB = 1
		delayC = 1.5
	} else {
		delayA = 0
		delayB = 0.5
		delayC = 1
	}

	window.addEventListener( 'resize', onResize )

	onResize()

	switchFunc()

	loaderTL

	.call(function(){

		if(page == 'home') {
			gsap
		}

	})

	.to('.loader svg', 0.5, {autoAlpha: 1, ease: 'power3.out' })

	.to('.loader path', 0.7, {x: -20, autoAlpha: 0, ease: 'power3.in' })

	.set([smootherWrap, burgerButton], {autoAlpha: 1})

	.call(function(){

		createSmooth()

		ScrollTrigger.create({
			onUpdate: pageScroll,
			start: 0,
			end: "max",
		})

	})

	.to(loader, 1, {autoAlpha: 0, ease: 'power3.out'})

	.call(function(){

		if(loaderLogo){
			loaderLogo.remove()
		}

		pageScroll()

		if(page == 'home' && !isMobile && isGL && isGL != 'false') { updateFolioCovers(true) }

	})

	.from('.burger_button span', 1, {y: -60, autoAlpha: 0, ease: 'power3.out', stagger: -0.1})

	.call(function(){

		startScroll()

	})

	if(page == 'home'){

		let target = document.querySelectorAll('.hero_text_slide')[0],
			split = new SplitText(target, {type:"chars", charsClass:"SplitClass"});

		gsap.from(split.chars, 0.8, {x: 60, autoAlpha: 0, ease: 'power3.out', stagger: 0.05, delay: delayC, onComplete: function(){

			window.addEventListener('resize', function(){
				split.revert()
			})

		}})

	}

}

function onResize(){

	vh = window.innerHeight * 0.01;

	document.documentElement.style.setProperty('--vh2', `${vh}px`)

	if(isMobile) {

		if(window.innerWidth != lastWindowWidth) {

			document.querySelector('body').classList.add('progress')

			setH()

		}

	} else {

		document.querySelector('body').classList.add('progress')

		setH()

	}

	function setH(){

		document.documentElement.style.setProperty('--vh', `${vh}px`)

		clearTimeout(window.resizedFinished)

		window.resizedFinished = setTimeout(function(){

			document.querySelector('body').classList.remove('progress')

			ScrollTrigger.refresh()

		}, 500)

	}

	lastWindowWidth = window.innerWidth

}

function stopScroll(){

	body.style.overflow = 'hidden'

	smoother.paused(true)

}

function startScroll(){

	body.style.overflow = 'auto'

	if(smoother){
		smoother.paused(false)
	}

}

function interactionY(){

	let _y = document.querySelectorAll('._y')

	if(!isMobile) {

		_y.forEach(function(element, i) {

			let tl = new gsap.timeline({paused:true}),
				ele = element.querySelector('._element'),
				ele2 = element.querySelector('._ele'),
				staggerVal,
				alphaVal,
				split1,
				split2;

			if(ele && ele.length != 0) {

				if(!ele.classList.contains('words')) {

					split1 = new SplitText(ele, {type:"chars", charsClass:"SplitClass"});
					split2 = split1.chars;
					staggerVal = 0.02
					alphaVal = 0

				} else {

					split1 = new SplitText(ele, {type:"words", wordsClass:"SplitClass"});
					split2 = split1.words;
					staggerVal = 0.1
					alphaVal = 0

				}

				tl.to(split2, 0.4, { y: '-100%', autoAlpha: alphaVal, ease: 'power3.in', stagger: staggerVal }, 0)

				.set(split2, { y: '100%' })

				.to(split2, 0.6, { y: '0%', autoAlpha: 1, ease: 'power3.out', stagger: staggerVal })

			} else {

				tl.to(ele2, 0.3, { y: '-110%', autoAlpha: 0, ease: 'power3.in' }, 0)

				.set(ele2, { y: '110%' })

				.to(ele2, 0.4, { y: '0%', autoAlpha: 1, ease: 'power3.out' })

			}

			element.animation = tl;

			element.addEventListener('mouseenter', function(){

				if(!this.animation.isActive()) {

					this.animation.restart();

				}

			}, {passive: true})

		})

	}

}

function stickyFunction(){

	let sticky = document.querySelectorAll('._sticky_wrap')

	if(sticky) {

		window.addEventListener('resize', function(){

			clearTimeout(window.resizedSticky)

			window.resizedSticky = setTimeout(function(){

				createSticky()

			}, 500)

		})

		createSticky()

		function createSticky(){

			sticky.forEach(function(e, i){

				if( ScrollTrigger.getById("t-"+i) ) { ScrollTrigger.getById("t-"+i).kill(true) }

			})

			ScrollTrigger.matchMedia({

				"(min-width: 961px)": function() {

					sticky.forEach(function(e, i){

						let stickyTarget = e.querySelector('._sticky');

						ScrollTrigger.create({
							id: 't-' + i,
							invalidateOnRefresh: true,
							trigger: stickyTarget,
							start: '0% 0%',
							endTrigger: sticky,
							end: '100% 100%',
							pin: true,
							pinSpacing: false,
						})

					})

				},

				"(max-width: 960px)": function() {

					sticky.forEach(function(e, i){

						if( ScrollTrigger.getById("t-"+i) ) { ScrollTrigger.getById("t-"+i).kill(true) }

					})

				}

			})

		}


	}

}

function globalFunction(){

	let rotateEle = document.querySelectorAll('._rotation')
	let xLines = document.querySelectorAll('.xline')
	let scrollBtn = document.getElementById('scrollBtn')

	rotateEle.forEach(function(e){

		gsap.timeline({
			scrollTrigger: {
				trigger: e,
				start: () => `-=${window.innerHeight}`,
				end: 'bottom',
				scrub: 1,
			},
			defaults:{ ease:'none'}
		})

		.to(e, {rotation: 360}, 0)

	})

	if(scrollBtn) {

		scrollBtn.addEventListener('click', function(){

			let element = document.querySelector('._getOffset'),
				offset = element.getBoundingClientRect().height;

			smoother.scrollTrigger.direction = 1

			gsap.to(smoother, {
				scrollTop: offset,
				duration: isMobile ? 0.2 : 2,
				ease: isMobile ? 'power3.out' : 'power3.inOut'
			})

		}, {passive: true})

	}

	cerchio()

	floating()

	menu()

	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: 'header',
			start: '0% 0%',
			end: 'bottom',
			scrub: 0.01,
		},
		defaults:{ ease:'none'}
	})

	.to('.burger_button > i', { scaleY: 1 })

	let playreel = document.querySelector('._play_reel');

	if(playreel && playreel.length != 0) {

		let getID = playreel.dataset.vimeo,
			reelWrap = document.querySelector('.reel_wrap'),
			getReel = reelWrap.querySelector('.get_reel'),
			reelClose = document.querySelector('.reel_close');

		playreel.addEventListener('click', function(){

			getReel.innerHTML = '<div style="height:100%;position:relative;"><iframe src="https://player.vimeo.com/video/'+getID+'?h=15d940850f&autoplay=1&color=fff&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>'

			gsap.to(reelWrap, 0.5, {autoAlpha: 1, ease: 'power3.out'})

		})

		reelClose.addEventListener('click', function(){

			gsap.to(reelWrap, 0.5, {autoAlpha: 0, ease: 'power3.out', onComplete: function(){

				getReel.innerHTML = ''

			}})

		})

	}

}

function menu(){

	let menuWrapper = document.querySelector('.menu_wrapper')

	upTL = new gsap.timeline({paused: true})

	upTL.to('.a', 0.5, {rotate: 40, y: -1, x: 6, ease: 'power3.inOut', scaleX: 0.9}, 0)

	.to('.b', 0.5, {rotate: 90, scaleX: 1.5}, 0)

	.to('.c', 0.5, {rotate: -40, y: -17, x: -6, autoAlpha: 1, ease: 'power3.inOut', scaleX: 0.9}, 0)

	let burgerTL = new gsap.timeline({paused: true})

	burgerTL

	.set('.menu_wrapper', {autoAlpha: 1}, 0)

	.to('.animate', 1, {scaleY: 1, ease: 'power3.inOut'}, 0)

	.to('.a', 0.5, {rotate: 45, y: 8, ease: 'power3.inOut'}, 0)

	.to('.b', 0.5, {rotate: -45, ease: 'power3.inOut'}, 0)

	.to('.c', 0.5, {autoAlpha: 0, ease: 'power3.inOut'}, 0)

	.to(smootherWrap, 1, {autoAlpha: 0.7, ease: 'power3.inOut'}, 0)

	.from('.menu_wrapper ._eleY', 1, {autoAlpha: 0, y: 100, ease: 'power3.out', stagger: 0.1}, 0.5)

	if(isTouch) {

		menuWrapper.addEventListener('touchend', sp)
		burgerButton.addEventListener('touchend', handler)
		body.addEventListener('touchend', function(){
			if(isMenuOpened){
				close()
			}
		})

	} else {

		menuWrapper.addEventListener('click', sp)
		burgerButton.addEventListener('click', handler)
		body.addEventListener('click', function(){
			if(isMenuOpened){
				close()
			}
		})

	}

	function sp(e){

		e.stopPropagation()

	}

	function close(){

		if(!isMenuOpened) {
			isMenuOpened = true
			smootherWrap.classList.add('disabled')
			burgerTL.timeScale(1.5).play()
			stopScroll()
		} else {
			isMenuOpened = false
			burgerTL.timeScale(1.7).reverse()
			smootherWrap.classList.remove('disabled')
			startScroll()
		}

	}

	function handler(e){

		e.stopPropagation()

		if(burgerButton.classList.contains('no-mix')) {

			burgerButton.classList.remove('no-mix')

			gsap.to(smoother, {
				scrollTop: 0,
				duration: 1,
				ease: 'power3.inOut'
			})

			upTL.reverse()

			gsap.to(burgerButton, 1, { y: 0, ease: 'power3.inOut' })

		} else {

			close()

		}

	}

}

function switchFunc() {

	switch (page) {
		case 'home':
		homePage()
		break;
		case 'about':
		aboutPage()
		break;
		case 'methodology':
		methodologyPage()
		break;
		case 'service':
		servicePage()
		break;
		case 'work':
		workPage()
		break;
		case 'case':
		casePage()
		break;
	}

}

function floating(){

	floatingButtons = document.querySelectorAll('.floating_button')

	floatingTL = new gsap.timeline();

	floatingTL.fromTo(floatingButtons, 0.3, { autoAlpha: 1 }, {autoAlpha: 0, ease: 'power3.out' })

	document.addEventListener('mousemove', (e) => {

		htmlMouse.x = e.pageX
		htmlMouse.y = e.pageY

		gsap.to('.floating_arrows', 0.3, {y: htmlMouse.y - pageYOffset, x: htmlMouse.x})

		updateFloating()

	})

}

function updateFloating(){

	if (floatingButtons && isPageReady) {

		mouseMoving = true

		floatingTL.reverse()

		floatingButtons.forEach(function(e, i){

			let wrapper = e.closest('.floating_wrap'),
				wrapperBounds = wrapper.getBoundingClientRect(),
				plusBounds = {w: (e.clientWidth/2) },
				getMouse = {x: htmlMouse.x - wrapperBounds.left, y: htmlMouse.y - wrapperBounds.top}

			gsap.to(e, 0.4, {x: (getMouse.x - plusBounds.w), y: (getMouse.y - plusBounds.w - window.pageYOffset) })

		})

	}

}

function appendImgs(){

	let appendBGs = document.querySelectorAll('.load_bg'),
		iMGs = document.querySelectorAll('.load_img'),
		videoBG = document.querySelectorAll('.load_video');

	videoBG.forEach(function(el){

		let s = el.dataset.src

		el.removeAttribute('data-src')

		createVideo(el, s)

	})

	iMGs.forEach(function(el){

		let s = el.dataset.src

		el.removeAttribute('data-src')
		el.setAttribute("src", support_format_webp(s))
		el.classList.remove('load_img')

		let newImg = new Image

		newImg.onload = function() {

			ScrollTrigger.refresh()

		}

		newImg.src = support_format_webp(s)

	})

	appendBGs.forEach(function(el){

		if(el.classList.contains('mobile')){
			if(isMobile) {
				excute(el)
			}
		} else {
			excute(el)
		}

	})

	lazybuild()

}

function createVideo($this, src) {

	let element = document.createElement('video')
	var source = document.createElement('source');

	source.src = src;
	source.type = 'video/mp4';
	element.loop = true
	element.setAttribute('muted', true)
	element.playsInline = true
	element.autoplay = true

	const promise = element.play()

	if(promise !== undefined){

		promise.catch(error => {

			document.addEventListener('click', function(){

				element.play()

			})

		})

	}

	$this.appendChild(element)

	element.appendChild(source)

	ScrollTrigger.refresh()

}

function excute(el){

	let s = el.dataset.src
	el.style.backgroundImage = 'url('+ support_format_webp(s) +')'
	el.classList.remove('load_img')

}

function lazybuild(){

	if(lazy.length != 0) {

		lazy.forEach(function(e){

			if(e.classList.contains('mobile')){
				if(isGL && isGL != 'false') {
					if(isMobile) {
						lazyHandler(e)
					}
				} else {
					lazyHandler(e)
				}
			} else {
				lazyHandler(e)
			}

		})

	}

}

function lazyHandler(e){

	let $this = e,
		tmp = document.createElement('div'),
		spinner = document.createElement('div');

	tmp.className = '_temp full_bg'
	spinner.className = 'spinner'

	tmp.append(spinner)
	$this.append(tmp)

}

function lazyLoad(element, s){

	let spinner = element.querySelector('.spinner')
	let tmp = element.querySelector('._temp')
	let img = element.querySelector('img')
	let newImg = new Image

	if(img) {

		img.setAttribute("src", support_format_webp(s))

	} else {

		img = element.querySelector('i')

		img.style.backgroundImage = 'url('+ support_format_webp(s) +')'

	}

	newImg.onload = function() {

		spinner.classList.add('pause')

		gsap.to(tmp, 0.5, {autoAlpha: 0, ease: 'power3.out', onComplete: function(){
			element.classList.add('ready')
			tmp.remove()
		}})

	}

	newImg.src = support_format_webp(s)

}

function support_format_webp(img){

	// var elem = document.createElement('canvas')

	// if (!!(elem.getContext && elem.getContext('2d'))) { return img.substr(0, img.lastIndexOf(".")) + ".webp" } else { return img}

	return img

}

function numberWithCommas(x) {

	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}

function pageScroll(){

	if (isPageReady) {

		if(isTouch){
			isScrolling = true
		}

		clearTimeout(window.resizedFinished)

		window.resizedFinished = setTimeout(function(){

			isScrolling = false
			mouseMoving = true

		}, 200)

		let maskElement = document.querySelectorAll('._mask')
		let eleWrap = document.querySelectorAll('._eleWrap')
		let splitWrap = document.querySelectorAll('._splitWrap')
		let maskWrap = document.querySelectorAll('._maskWrap')
		let counters = document.querySelectorAll('.counter')
		let burgerPlaceholder = document.querySelector('.burger_placeholder')

		scrollSpeed = checkScrollSpeed(pageYOffset)

		mouseMoving = false

		if(floatingTL && !mouseMoving){
			floatingTL.play()
			mouseMoving = true
		}

		if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {

			gsap.to(burgerButton, 0.8, {y: function(){
				return burgerPlaceholder.getBoundingClientRect().top
			}, ease: 'power3.out'})

			burgerButton.classList.add('no-mix')

			upTL.play()


		} else {

			gsap.to('.burger_button', 0.8, {y: 0, ease: 'power3.out'})

			burgerButton.classList.remove('no-mix')

			upTL.reverse()

		}

		if(counters.length != 0) {

	        counters.forEach(function (e) {

	            let $this = e;

				if( ScrollTrigger.positionInViewport($this) <= 0.9) {

					if(!$this.classList.contains('inview')) {

						$this.classList.add('inview')

						gsap.set($this, {autoAlpha: 1})

						gsap.fromTo($this, 0.7, {y: '100%'}, {y: 0, ease: 'power3.out'})

						gsap.from($this, {
							textContent: 0,
							duration: 3,
							ease: "power3.out",
							snap: { textContent: 1 },
							stagger: {
							each: 1.0,
							onUpdate: function() {
								this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
							},
							}
						});

					}

	            }

	        });

	    }

		if(maskWrap.length != 0) {

			maskWrap.forEach(function(e){

				let $this = e,
					getMask = $this.querySelector('.mask'),
					getIMG = $this.querySelector('._maskSRC');

				if( ScrollTrigger.positionInViewport($this) <= 0.9) {

					if(!$this.classList.contains('inview')) {

						$this.classList.add('inview')

						gsap.to(getMask, 1.5, { scaleY: 0, ease: 'power3.inOut' })

						gsap.from(getIMG, 2, { scale: 2.2, ease: 'power3.out', onComplete:function(){

							$this.classList.remove('._maskWrap')
							getIMG.classList.remove('_maskSRC')
							getMask.remove()

						} })

					}

				}

			})

		}

		if(clientsTL) {
			if( ScrollTrigger.isInViewport('.clients_wrap') ) {
				if(window.innerWidth > 580) {
					clientsTL.play()
				}
			} else {
				clientsTL.pause()
			}
		}


		if(lazy.length != 0) {

			lazy.forEach(function(e){

				if(e.classList.contains('mobile')){
					if(isGL && isGL != 'false') {
						if(isMobile) {
							scrollHandler(e)
						}
					} else {
						scrollHandler(e)
					}
				} else {
					scrollHandler()
				}

				function scrollHandler(){

					let $this = e,
						src = $this.dataset.src;

					if( ScrollTrigger.isInViewport($this) ) {

						if(!$this.classList.contains('inview')) {

							$this.classList.add('inview')

							lazyLoad($this, src)

						}

					}

				}

			})

		}

		if( eleWrap.length != 0 ) {

			eleWrap.forEach(function(e, i){

				let $this = e,
					eleY = $this.querySelectorAll('._eleY'),
					eleX = $this.querySelectorAll('._eleX'),
					eleS = $this.querySelectorAll('._eleS');

				if( ScrollTrigger.positionInViewport(e) <= 0.9) {
					animateEle($this, eleY, eleX, eleS)
				}

			})

		}

		if( splitWrap.length != 0 ) {

			splitWrap.forEach(function(e, i){

				let $this = e,
					getWords = $this.querySelectorAll('._splitWords'),
					getLines = $this.querySelectorAll('._splitLines');


				if( ScrollTrigger.positionInViewport(e) <= 0.9) {
					getWords.forEach(function(e, i){
						split($this, getWords, null, i)
					})
					getLines.forEach(function(e, i){
						split($this, null, getLines, i)
					})
				}

			})

		}

		if( maskElement.length != 0 ) {

			maskElement.forEach(function(e, i){

				let $this = e

				if( ScrollTrigger.positionInViewport(e) <= 0.9) {

					if( !$this.classList.contains('inview') ) {

						$this.classList.add('inview')

						let splitLines = new SplitText($this, {type:"lines", linesClass:"SplitClass"});
						let splitWords = new SplitText($this.querySelectorAll('.SplitClass'), {type:"words", wordsClass:"SplitClass"});

						gsap.set($this, {autoAlpha: 1})

						gsap.from(splitWords.words, 0.7, { y: '100%', ease: 'power3.out', stagger: 0.1,
							onComplete: function(){
								window.addEventListener('resize', function(){
									splitWords.revert()
									splitLines.revert()
									$this.classList.remove('_mask')
								})
							}
						})

					}

				}

			})

		}

		if(page == 'home' && !isMobile && isGL && isGL != 'false') { updateFolioCovers(true) }

	}

}

function animateEle($this, eleY, eleX, eleS) {

	if( !$this.classList.contains('inview') ) {

		$this.classList.add('inview')

		gsap.set($this, {autoAlpha: 1}, 0)

		if(eleS.length != 0) {
			gsap.set(eleS, { scale: 0, autoAlpha: 0})
			gsap.to(eleS, 0.7, { scale: 1, autoAlpha: 1, ease: 'power3.out' })
		}
		if(eleY.length != 0) {
			gsap.set(eleY, { y: 50, autoAlpha: 0})
			gsap.to(eleY, 0.7, { y: 0, autoAlpha: 1, ease: 'power3.out', stagger: 0.25 })
		}

		if(eleX.length != 0) {

			gsap.set(eleX, { x: 150, autoAlpha: 0})
			gsap.to(eleX, 0.7, { x: 0, autoAlpha: 1, ease: 'power3.out', stagger: 0.1 })
		}
	}

}

function split($this, getWords, getLines, i) {

	if( !$this.classList.contains('inview') ) {

		$this.classList.add('inview')

		gsap.set(getWords, {autoAlpha: 1, delay: 0.3}, 0)

		if(getWords && getWords.length != 0) {

			let splitWords = new SplitText(getWords, {type:"words", wordsClass:"SplitClass"});

			if(getWords.classList.contains('dirX')) {

				gsap.set(splitWords.words, { x: 20, autoAlpha: 0})
				gsap.to(splitWords.words, 1, { x: 0, autoAlpha: 1, ease: 'power3.out', stagger: 0.1, onComplete: function(){
					window.addEventListener('resize', function(){
						splitWords.revert()
					})
				} })

			} else {
				gsap.set(splitWords.words, { y: 20, autoAlpha: 0})
				gsap.to(splitWords.words, 0.5, { y: 0, autoAlpha: 1, ease: 'power3.out', stagger: 0.1, onComplete: function(){
					window.addEventListener('resize', function(){
						splitWords.revert()
					})
				} })
			}
		}

		if(getLines && getLines.length != 0) {
			gsap.set(getLines, {autoAlpha: 1})
			let splitLines = new SplitText(getLines, {type:"lines", linesClass:"SplitClass"});
			gsap.set(splitLines.lines, { y: 20, autoAlpha: 0})
			gsap.to(splitLines.lines, 0.5, { y: 0, autoAlpha: 1, ease: 'power3.out', stagger: 0.1, onComplete: function(){
				if(!$this.classList.contains('no_revert')) {
					window.addEventListener('resize', function(){
						splitLines.revert()
					})
				}
			} })
		}

	}

}

const getDefaultFontSize = (element) => {

	return (window.getComputedStyle(element).getPropertyValue('font-size')).replace(/px/g,'')

}

function getTranslateXY(element) {

	const getEle = document.querySelector(element)
	const style = window.getComputedStyle(getEle)
	const matrix = new DOMMatrixReadOnly(style.transform)
	return {
		translateX: matrix.m41,
		translateY: matrix.m42
	}

}

function clients(){

	let totalGroups = document.querySelectorAll('.clients_group.f'),
		ul = document.querySelector('.clients_pages ul'),
		pages;

	totalGroups.forEach(function(e, index){

		let li = document.createElement('li'),
			span = document.createElement('span'),
			i = document.createElement('i');

		li.appendChild(span)
		span.appendChild(i)
		ul.appendChild(li)
		li.setAttribute('data-id', index)

	})

	pages = ul.querySelectorAll('li')

	pages[0].classList.add('active')

	pages.forEach(function(page){

		page.addEventListener('click', function(){

			if(!page.classList.contains('active')) {

				pages.forEach(e => e.classList.remove('active') )

				page.classList.add('active')

				resetTimer(page)

				clientsFlk.select(page.dataset.id, true, false)

			}

		})

		page.addEventListener('mouseenter', function(){

			if(page.classList.contains('active')) {

				clientsTL.pause()

			}

		})

		page.addEventListener('mouseleave', function(){

			if(page.classList.contains('active')) {

				clientsTL.play()

			}

		})

	})

	resetTimer(pages[0])

	function resetTimer(page){

		let getI = page.querySelector('i')

		if(clientsTL) { clientsTL.kill() }

		clientsTL = new gsap.timeline({paused: true})

			clientsTL

			.to('.clients_pages ul i', 0.5, {scaleX: 0 }, 0)
			
			.to(getI, 5, {scaleX: 1, ease: 'power0.none' })

			.call(function(){

				next()

			})

		if ( window.innerWidth > 580 ) {

			clientsTL.play()

		}

	}

	function next(){

		let current = ul.querySelector('li.active'),
			next = current.nextSibling,
			target;

		if(next) {
			target = next
		} else {
			target = pages[0]
		}

		target.click()

	}

	var options = {
		prevNextButtons: false,
		accessibility: true,
		pageDots: false,
		percentPosition: true,
		cellAlign: 'left',
		draggable: window.innerWidth <= 580 ? true : false,
		freeScroll: window.innerWidth <= 580 ? true : false,
		contain: true,
		bounce: false,
		resize: true
	}

	let clientsFlk = new Flickity('.clients_slider', options)

	window.addEventListener('resize', function(){

		if ( window.innerWidth <= 580 ) {

			clientsFlk.options.freeScroll = true
			clientsFlk.options.draggable = true

			if(clientsTL) { clientsTL.pause() }

		} else {

			clientsFlk.options.freeScroll = false
			clientsFlk.options.draggable = false

			if(clientsTL) { clientsTL.play() }

		}

  		clientsFlk.reposition()
  		clientsFlk.updateDraggable()

	})

	clientsFlk.on( 'scroll', function( progress ) {

		let val = Math.max( 0, Math.min( 1, progress ) )
		
		gsap.set('.clients_progress i', {scaleX: val})

	})

}

function homePage(){

	let tl = new gsap.timeline({paused: true})

	let carousel = document.querySelector('.hero_slides'),
		imgs = carousel.querySelectorAll('i'),
		_h = document.querySelectorAll('.hero_cta, header, .banner_nav_wrap, .slider_arrows'),
		next = document.querySelector('._next'),
		prev = document.querySelector('._prev'),
		docStyle = document.documentElement.style,
		transformProp = typeof docStyle.transform == 'string' ? 'transform' : 'WebkitTransform';


	let flkty = new Flickity(carousel, {
		prevNextButtons: false,
		accessibility: false,
		pageDots: false,
		percentPosition: true,
		bounce: isMobile ? false : true,
		imagesLoaded: true,
		dragThreshold: isMobile || window.innerWidth <= 580 ? 10 : 3,
		cellAlign: 'left',
	})

	let titlesFlk = new Flickity('.hero_text_set', {
		prevNextButtons: false,
		draggable: false,
		pageDots: false,
		percentPosition: true,
		rightToLeft: true,
	})

	next.addEventListener('click', function(){ flkty.next( true, false ); titlesFlk.select( flkty.selectedIndex, false, true ); })
	prev.addEventListener('click', function(){ flkty.previous( true, false ); titlesFlk.select( flkty.selectedIndex, false, true ); })

	flkty.on( 'dragEnd', function( index ) {

		titlesFlk.select( flkty.selectedIndex, false, true )

	})

	if(!isTouch) {

		let getDragX,
			getDragY;

		gsap.to('.drag_tip', 0.5, {autoAlpha: 1, ease: 'power3.out'})

		tl.to('.drag_tip i', 0.3, {scale: 1.2, ease: 'power3.inOut'})

		_h.forEach(function(e){

			e.addEventListener('mouseenter', function(e){

				gsap.to('.drag_tip', 0.5, {autoAlpha: 0, ease: 'power3.out'})

			})

			e.addEventListener('mouseleave', function(e){

				gsap.to('.drag_tip', 0.5, {autoAlpha: 1, ease: 'power3.out'})

			})


		})

		window.addEventListener('mousemove', function(e){
			getDragX = e.pageX
			getDragY = e.pageY

			updateDrag(getDragX, getDragY)

		})

		flkty.on( 'dragMove', function(event, pointer, moveVector) {

			updateDrag(getDragX + moveVector.x, getDragY + moveVector.y)

		})

	}

	function updateDrag(getX, getY){
		let drag = document.querySelector('.drag_tip'),
			calcX = getX - (drag.offsetWidth/2),
			calcY = getY - (drag.offsetHeight/2)

		if(!isMenuOpened) {
			gsap.to(drag, 0.5, {x: calcX, y: calcY, ease: 'power3.out'})
		}
	}

	flkty.on( 'pointerDown', function(e, pointer) {

		if(!e.target.classList.contains('rounded_button')) {

			if(!isMobile && window.innerWidth > 580){
				gsap.to('.hero_text_set', 0.4, {scale: 0.7})
				gsap.to('.hero_slides', 0.4, {scale: 0.9})
				gsap.to('.hero_slide > span', 0.4, {scale: 0.9})
			}

			gsap.to('.hero_cta', 0.4, {autoAlpha: 0})

			if(!isTouch) {

				tl.play()

			}

		}

	})

	flkty.on( 'pointerUp', function() {

		if(!isMobile && window.innerWidth > 580){
			gsap.to('.hero_slides, .hero_slide > span, .hero_text_set', 1, {scale: 1})
		}

		gsap.to('.hero_cta', 0.4, {autoAlpha: 1})

		if(!isTouch) {

			tl.reverse()

		}

	})

	flkty.on( 'scroll', function() {

		gsap.to('.hero_text_set .flickity-slider', 1, {x: -getTranslateXY('.hero_slides .flickity-slider').translateX })

		flkty.slides.forEach( function( slide, i ) {

			var img = imgs[i],
				x = ( slide.target + flkty.x ) * -0.3

			img.style[ transformProp ] = 'translateX(' + x  + 'px)'

		})

	})

	if(!isMobile) {

		if(isGL && isGL != 'false') {
			createGL()
		}

		projectCards()

	}

	clients()

}

function projectCards(){

	let project = document.querySelectorAll('.has_project')

	project.forEach(function(element, i) {

		let tl = new gsap.timeline({paused:true}),
			ele = element.querySelectorAll('._ele');

		tl.from(ele, 0.5, { y: 20, autoAlpha: 0, ease: 'power3.out', stagger: 0.1 }, 0.2)


		element.animation = tl;

		element.addEventListener('mouseenter', function(){

			this.animation.play()

		})

		element.addEventListener('mouseleave', function(){

			this.animation.reverse()

		})

	})

}

function aboutPage(){

	let filtersList = new Flickity('.side_images', {
		prevNextButtons: false,
		accessibility: true,
		pageDots: false,
		percentPosition: true,
		cellAlign: 'left',
		freeScroll: true,
		contain: true,
		watchCSS: true,
	})

	let team = new Flickity('.team_animate', {
		prevNextButtons: false,
		accessibility: true,
		pageDots: false,
		percentPosition: true,
		cellAlign: 'left',
		freeScroll: true,
		contain: true,
		watchCSS: true,
	})

	let teamWrap = document.querySelector('.team_wrap'),
		teamAnimate = document.querySelector('.team_animate')

	window.addEventListener('resize', function(){

		clearTimeout(window.resizedTeam)

		window.resizedTeam = setTimeout(function(){

			teamTrigger()

		}, 500)

	})

	teamTrigger()

	function teamTrigger(){

		ex()

		ScrollTrigger.matchMedia({

			"(min-width: 581px)": function() {

				gsap.timeline({
						scrollTrigger: {
						id: 'team',
						invalidateOnRefresh: true,
						trigger: '.team_section',
						start: '0% 0%',
						end: () => `+=${ teamAnimate.offsetWidth }`,
						pin: true,
						pinSpacing: true,
						scrub: true,
					},
					defaults:{ ease:'none'}
				})

				.to(teamAnimate, {x: - teamAnimate.offsetWidth + teamWrap.offsetWidth })

			},

			"(max-width: 580px)": function() {

				ex()

			}

		})

		function ex(){

			if( ScrollTrigger.getById("team") ) { ScrollTrigger.getById("team").kill(true) }

			gsap.set(teamAnimate, {x: 0})

		}

	}

	clients()


	let wrap = document.querySelector('.symp_wrap'),
		element = document.createElement('video'),
		source = document.createElement('source'),
		src,
		video;

	source.src = wrap.dataset.src;
	source.type = 'video/mp4';
	source.preload = 'auto';
	element.setAttribute('muted', true)
	element.playsInline = true
	element.classList.add('video-scrub')

	element.appendChild(source)
	wrap.appendChild(element)

	video = wrap.querySelector('video')
	src = video.currentSrc || video.src

	function once(el, event, fn, opts) {
		var onceFn = function (e) {
			el.removeEventListener(event, onceFn)
			fn.apply(this, arguments)
		}
		el.addEventListener(event, onceFn, opts)
		return onceFn
	}

	once(document.documentElement, "touchstart", function (e) {
		video.play()
		video.pause()
	})

	let vidTL = gsap.timeline({
		defaults: { duration: 1 },
		scrollTrigger: {
			trigger: wrap,
			start: "top top",
			end: () => `+=${ window.innerHeight * 10 }`,
			scrub: true,
			pin: true
		}
	})

	once(video, "loadedmetadata", () => {
		vidTL.fromTo( video, { currentTime: 0 }, { currentTime: video.duration || 1 })
	})

	setTimeout(function () {
		if (window["fetch"]) {
			fetch(wrap.dataset.src).then((response) => response.blob()).then((response) => {
				var blobURL = URL.createObjectURL(response)

				var t = video.currentTime

				once(document.documentElement, "touchstart", function (e) {
					video.play()
					video.pause()
				})

				video.setAttribute("src", blobURL)
				video.currentTime = t + 0.01
			})
		}
	}, 1000)

}

function methodologyPage(){

	let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight),
		portfolioScreen = gsap.utils.toArray('.services_screen'),
		bgs = document.querySelectorAll('.services_screen > i');

	portfolioScreen.forEach((section, i) => {

		section.bg = bgs[i]

		gsap.timeline({
			scrollTrigger: {
				invalidateOnRefresh: true,
				trigger: section,
				start: () => i ? "top bottom" : "top top", 
				end: "bottom top",
				scrub: true,
			},
			defaults:{ ease:'none'}
		})

		.fromTo(section.bg, {backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(section.bg)}px` : "50% 0px"}, {backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(section.bg))}px`}, 0)

	})

	ScrollTrigger.create({
		invalidateOnRefresh: true,
		trigger: '.services_sticky',
		start: '0% 0%',
		endTrigger: '.services_wrap',
		end: '100% 100%',
		pin: true,
		pinSpacing: false,
	})

}

function servicePage(){

	let portfolio = document.querySelector('.stories_wrap'),
		cards = gsap.utils.toArray('.story_card'),
		totalCards = cards.length - 1;

	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: portfolio,
			start: '0% 0%', 
			end: () => `+=${ window.innerHeight * 2 }`,
			scrub: 1,
			pin: true,
			pinSpacing: true,
			snap: {
				snapTo: "labels",
				duration: {min: 0.2, max: 0.4},
				delay: isMobile ? 4000 : 0,
				ease: "power3.out"
			},
		},
		defaults:{ ease:'none'}
	})

	.addLabel("Card1")

	.to(cards[4], 1, {y: 50, autoAlpha: 0, scale: 1.1}, 0)
	.to(cards[3], 1, {y: 0, scale: 1.0}, 0)
	.to(cards[2], 1, {y: -50, scale: 0.9}, 0)
	.to(cards[1], 1, {y: -100, scale: 0.8}, 0)
	.to(cards[0], 1, {y: -150, scale: 0.7}, 0)
	.to(cards[1].querySelector('.folio_project'), 1, {autoAlpha: 1}, 0)
	.to(cards[0].querySelector('.folio_project'), 1, {autoAlpha: 0.3}, 0)

	.addLabel("Card2")

	.to(cards[3], 1, {y: 50, autoAlpha: 0, scale: 1.1}, 1)
	.to(cards[2], 1, {y: 0, scale: 1.0}, 1)
	.to(cards[1], 1, {y: -50, scale: 0.9}, 1)
	.to(cards[0], 1, {y: -100, scale: 0.8}, 1)
	.to(cards[0].querySelector('.folio_project'), 1, {autoAlpha: 1}, 1)

	.addLabel("Card3")

	.to(cards[2], 1, {y: 50, autoAlpha: 0, scale: 1.1}, 2)
	.to(cards[1], 1, {y: 0, scale: 1.0}, 2)
	.to(cards[0], 1, {y: -50, scale: 0.9}, 2)

	.addLabel("Card4")

	.to(cards[1], 1, {y: 50, autoAlpha: 0, scale: 1.1}, 3)
	.to(cards[0], 1, {y: 0, scale: 1.0}, 3)


	if(!isMobile) {

		projectCards()

	}

}

function workPage(){

	let ajaxPageLoader = new AjaxPageLoader()

	ajaxPageLoader.setDefaultTarget('#getProjects')

	ajaxPageLoader.setAfterAjaxLoadingEvent((ajaxLink) => {

		gsap.from('.project', 1, {autoAlpha: 0, y: 200, ease: 'power3.out', stagger: 0.1})

		lazy = document.querySelectorAll('.lazy')

		lazybuild()

		smootherFunc()

		pageScroll()

		if(!isMobile) {

			htmlMouse = {x: -1000, y: -1000}

			floating()

			updateFloating()

			projectCards()

		}

		ScrollTrigger.refresh()

		body.classList.remove('wait')

	})

	let dropdown = document.querySelector('.dropdown'),
		sector = document.querySelectorAll('.dropdown a');


	dropdown.addEventListener('click', function(){

		if(!dropdown.classList.contains('active')) {

			dropdown.classList.add('active')

		} else {

			dropdown.classList.remove('active')

		}

	})


	sector.forEach(function(e){

		e.addEventListener('click', function(){

			let getLabel = document.querySelector('.getLabel');
				
			getLabel.innerHTML = e.innerHTML

			gsap.to('.project', 0.5, {autoAlpha: 0, ease: 'power3.in'})

			body.classList.add('wait')

			sector.forEach($this => $this.classList.remove('active') )

			e.classList.add('active')

		})

	})

	if(!isMobile) {

		projectCards()

	}

}

function casePage(){

	let sliders = document.querySelectorAll('.slider')

	sliders.forEach(function(slider){

		slider.addEventListener('focus', updateCounter)
		slider.addEventListener('input', updateCounter)

		function updateCounter(e){

			let sliderPos = e.target.value,
				wrap = slider.closest('.before_wrap'),
				img = wrap.querySelector('.foreground-img'),
				button = wrap.querySelector('.slider-button')

			gsap.to(button, 0.3, {left: `calc(${sliderPos}% - 18px)`, ease: 'power3.out'})

			gsap.to(img, 0.3, {webkitClipPath: 'polygon(0% 100%, 0% 0%, '+sliderPos+'% 0%, '+sliderPos+'% 100%)', clipPath: 'polygon(0% 100%, 0% 0%, '+sliderPos+'% 0%, '+sliderPos+'% 100%)', ease: 'power3.out'})

		}

	})

	let wrap = document.querySelectorAll('.video-item')

	wrap.forEach(function(e){

		var options = {
			id: e.dataset.id,
			background: e.dataset.type == 'long' ? false : true,
			controls: e.dataset.type == 'long' ? true : false,
			loop: true,
			autoplay: true,
			muted: true,
			responsive: true,
			autopause: false
		}

		var player = new Vimeo.Player(e, options)

	})

}

function createGL(){

	texLoader = new TextureLoader()

	getUniforms = {
		uTexture: { value: 0 },
		uOffset: { value: new Vector2(0.0, 0.0) },
		uAlpha: { value: 1 }
	}

	let gl_material = new ShaderMaterial({
		uniforms: {
			tMap: { value: null },
			uPlaneSizes: { value: new Vector2(1000, 0.0) },
			uImageSizes: { value: new Vector2(100, 0.0) },
			uViewportSizes: { value: [window.innerWidth, window.innerHeight] },
			uStrength: { value: 0 },
			uPosX: { value: 326 }
		},
		vertexShader: `
			#define PI 3.1415926535897932384626433832795
			precision highp float;
			precision highp int;
			uniform float uStrength;
			uniform float uPosX;
			uniform vec2 uViewportSizes;
			varying vec2 vUv;
			void main() {
				vec4 newPosition = modelViewMatrix * vec4(position, 1.0);
				newPosition.z += sin(newPosition.y / uViewportSizes.y * PI + PI / 2.0) * -uStrength;
				vUv = uv;
				gl_Position = projectionMatrix * newPosition;
			}
		`,
		fragmentShader: `
			precision highp float;
			uniform vec2 uImageSizes;
			uniform vec2 uPlaneSizes;
			uniform sampler2D tMap;
			varying vec2 vUv;
			void main() {
				vec2 ratio = vec2(
					min((uPlaneSizes.x / uPlaneSizes.y) / (uImageSizes.x / uImageSizes.y), 1.0),
					min((uPlaneSizes.y / uPlaneSizes.x) / (uImageSizes.y / uImageSizes.x), 1.0)
				);
				vec2 uv = vec2(
					vUv.x * ratio.x + (1.0 - ratio.x) * 0.5,
					vUv.y * ratio.y + (1.0 - ratio.y) * 0.5
				);
				gl_FragColor.rgb = texture2D(tMap, uv).rgb;
				gl_FragColor.a = 1.0;
			}
		`,
	})

	function init(){

		gl_container = document.createElement('div')
		gl_container.className = 'gl'

		camera = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 10000 )
		camera.updateMatrix()
		camera.updateMatrixWorld()

		scene = new Scene()

		renderer = new WebGLRenderer({ alpha: true, logarithmicDepthBuffer: true, powerPreference: 'high-performance' })
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		renderer.outputEncoding = sRGBEncoding

		gl_container.appendChild( renderer.domElement )

		document.body.appendChild(gl_container)

		window.addEventListener('resize', glResize)

		createFolioCovers()

		glResize()

		animate()

	}

	function render(){

		gl_meshes.forEach(function(e,i){

			gsap.to(e.material.uniforms.uStrength, 0.3, {value: limitNumberWithinRange((scrollSpeed * -10), -100, 100), ease: 'none'})

		})

		renderer.render(scene, camera)

	}

	function animate(){

		render()

		requestAnimationFrame(animate)

	}

	function glResize(){

		fov = (180 * (2 * Math.atan(window.innerHeight / 2 / (perspective)))) / Math.PI
		camera.fov = fov
		camera.aspect = window.innerWidth / window.innerHeight
		camera.position.z = perspective
		camera.updateProjectionMatrix()
		renderer.setSize( window.innerWidth, window.innerHeight )
		renderer.render(scene, camera)
		updateFolioCovers()

	}

	function createFolioCovers(){

		let projectMedia = document.querySelectorAll('.folio_cover')

		projectMedia.forEach(function(e, i){

			e.setAttribute("data-name", 'glimg-' + i)

			e.querySelector('i').style.visibility = 'hidden'

			let getIMG = e.dataset.src
			let material = gl_material.clone()

			let txtLoad = texLoader.load(getIMG, handler)
		    txtLoad.needsUpdate = true
    		txtLoad.minFilter = LinearFilter

			function handler(){

				material.uniforms.tMap.value = txtLoad

				gl_meshes[i] = new Mesh( new PlaneGeometry(1, 1, 32, 32), material)

				gl_meshes[i].scale.set(1, 1, 1)

				gl_meshes[i].name = 'glimg-' + i

				scene.add(gl_meshes[i])

				updateFolioCovers()

			}

		})

	}

	init()

}

function updateFolioCovers(val){

	gl_meshes.forEach(function(e,i){

		let element = document.querySelector("[data-name='"+e.name+"']")
		let elemRect = element.getBoundingClientRect()
		let width = elemRect.width
		let height = elemRect.height
		let posX = elemRect.left
		let posY = elemRect.top
		let halfX = window.innerWidth / 2
		let halfY = window.innerHeight / 2
		let valX = - halfX + (elemRect.width/2) + posX
		let valY = halfY - (elemRect.height/2) - posY

		if(val) {
			e.position.x = valX
			e.position.y = valY
		}

		if(!val){
			e.scale.set(width, height, 1)
			e.material.uniforms.uImageSizes.value.x = width
			e.material.uniforms.uPlaneSizes.value.x = width

			e.material.uniforms.uImageSizes.value.y = height
			e.material.uniforms.uPlaneSizes.value.y = height
		}


	})

}

function cerchio(){

	if(!isMobile){

		let cerchio = document.querySelectorAll('.mg')

		cerchio.forEach(function(el){
			document.addEventListener('mousemove', function(e){
				magnetize(el, e);
			})
		})

		function magnetize(el, e){

			var getx = e.pageX,
				getY = e.pageY - window.pageYOffset

			const item = el
			const customDist = item.dataset.dist * 20 || 120;
			const centerX = item.getBoundingClientRect().left + (item.getBoundingClientRect().width/2);
			const centerY = (item.getBoundingClientRect().top + (item.getBoundingClientRect().height/2));

			var deltaX = Math.floor((centerX - getx)) * -0.6;
			var deltaY = Math.floor((centerY - getY)) * -0.6;
			var distance = calculateDistance(item, getx, getY);

			if(customDist == 0) {
				deltaX = 0
				deltaY = 0
			}

			if(distance < customDist){

				if( item.classList.contains('mg') ) {
					if(isPageReady){
						gsap.to(item, 0.5, {y: deltaY, x: deltaX, ease: 'none'})
					}
					item.classList.add('magnet')
				}

			} else {

				gsap.to(item, 0.5, {y: 0, x: 0, ease: 'none'})
				item.classList.remove('magnet')

			}

		}

		function calculateDistance(elem, mouseX, mouseY) {
			return Math.floor(Math.sqrt(Math.pow(mouseX - (elem.getBoundingClientRect().left+(elem.getBoundingClientRect().width/2)), 2) + Math.pow(mouseY - (elem.getBoundingClientRect().top+(elem.getBoundingClientRect().height/2)), 2)));
		}

		function lerp(a, b, n) {
			return (1 - n) * a + n * b
		}

	}

}